import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { SideNavBar } from "../side-nav-bar/SideNavBar";
import { useEffect, useState } from "react";
import { APIRequest1 } from "../../utils/api_utils";
import "./pointTransfer.css";
import { jwtDecode } from "jwt-decode";
import { useSnackbar } from "notistack";
import ConfettiExplosion from "react-confetti-explosion";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useAuth } from "../../auth/authContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { addDataToProgramDataStore } from "../../data-store/program-data-store";
import { getDataFromProgramDataStore } from "../../data-store/program-data-store";
import { getDataFromTransferPartnerDataStore } from "../../data-store/transfer-partner-data-store";

import SuccessModal from "./SuccessModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
};

export const PointsTransfer = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  let localData = localStorage.getItem("verifySession");
  let parsedData = null;

  parsedData = JSON.parse(localData);

  const baseUrl = process.env.REACT_APP_CORE_ENGINE_URL;

  const PartnerPoints = 1000;

  let decoded = {};
  if (localStorage.getItem("token")) {
    decoded = jwtDecode(localStorage.getItem("token"));
  }

  const [points, setPoints] = useState(
    JSON.parse(localStorage.getItem("userPoints"))
      ? Math.round(JSON.parse(localStorage.getItem("userPoints")) / 2)
      : 0
  );

  const [transferedPoints, setTransferedPoints] = useState(0);

  const [calculatedPoints, setCalculatedPoints] = useState(0);
  const [partnerMemberId, setPartnerMemberId] = useState("1001");
  const [memberId, setmemberId] = useState("");
  const [sideMenu, setSideMenu] = useState(false);
  const [allPrograms, setAllPrograms] = useState([]);

  const [allTransferPartner, setAllTransferPartner] = useState([]);
  const [programPoints, setProgramPoints] = useState([]);
  const [programPoints1, setProgramPoints1] = useState([]);
  const [selectedToSideProgram, setSelectedToSideProgram] = useState(null);

  const [isSubmittedSuccessfull, setIsSubmittedSuccessfull] = useState(false);

  const [succcessModal, setSucccessModal] = useState(false);

  const [open, setOpen] = useState(false);
  const [onSubmitLoading, setOnSubmitLoading] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState(points);
  const [calculateLoading, setCalculateLoading] = useState(false);
  const [doTransferPoints, setDoTransferPoints] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const debounceDelay = 300;

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    // setIsSubmittedSuccessfull(false);
    setmemberId("");
    setOnSubmitLoading(false);
  };

  const handleCloseSuccessModal = () => {
    setOnSubmitLoading(false);
  };

  const handleSubmit = async () => {
    setOnSubmitLoading(true);
    try {
      if (selectedToSideProgram?.toSidePoints > 0) {
        const response = await APIRequest1(
          "post",
          baseUrl,
          "/transfer/doTransferPoints",

          {
            fromMemberId: JSON.parse(localStorage.getItem("userInfo"))
              ?.memberId,
            transferPartnerCode: JSON.parse(
              localStorage.getItem("transferPartner")
            ).partnerCode,
            toMemberId: memberId,
            programCode: selectedToSideProgram?.programCode,
            fromSidePoints: points,
            toSidePoints: selectedToSideProgram?.toSidePoints,
          }
        );

        enqueueSnackbar("Submitted successfully", {
          variant: "success",
          autoHideDuration: 1700,
        });
        setDoTransferPoints(response?.data);
        setIsSubmittedSuccessfull(true);
        setSucccessModal(true);
        handleClose();
        setOnSubmitLoading(false);

        if (response.data.transferPartnerMemberBalance) {
          localStorage.setItem(
            "userPoints",
            response.data.transferPartnerMemberBalance
          );
        }

        // setPoints(JSON.parse(localStorage.getItem("userPoints")));

        if (localStorage.getItem("userPoints")) {
          setPoints(JSON.parse(localStorage.getItem("userPoints")));
        }
      }
    } catch (error) {
      // console.log(error);
      enqueueSnackbar(error, {
        variant: "error",
      });
      setOnSubmitLoading(false);
    }
  };

  const handleCalculate = async () => {
    setCalculateLoading(true);
    try {
      const response = await APIRequest1(
        "post",
        baseUrl,
        "/transfer/calculateToSidePoints",
        {
          transferPartnerCode: JSON.parse(
            localStorage.getItem("transferPartner")
          ).partnerCode,

          fromSidePoints: points ? points : 0,

          programCodes: [...allPrograms],
        }
      );

      if (response.data) {
        setCalculateLoading(false);
        setProgramPoints(response?.data?.toSidePointsList);
      }
    } catch (error) {
      setCalculateLoading(false);
      enqueueSnackbar(error, { variant: "error" });
    }
  };

  useEffect(() => {
    if (allPrograms?.length > 0) {
      handleCalculate(points);
    }
  }, [allPrograms]);

  const getAllPrograms = async () => {
    try {
      const response = await APIRequest1(
        "get",
        baseUrl,
        "/program/getAllPrograms"
      );

      if (response.data && response.data.length > 0) {
        let structuredData = [];
        let temp = response.data.map((item) =>
          structuredData.push(item?.programCode)
        );

        if (structuredData.length > 0) {
          setAllPrograms(structuredData);
        }
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
    }
  };

  const getTransferPartner = async () => {
    try {
      const response = await APIRequest1(
        "post",
        baseUrl,
        "/transferPartner/getTransferPartnersFromDB",
        {}
      );
      let structuredData = [];
      let temp = response.data.transferPartnerVOList.map((item) => {
        return structuredData.push(item.partnerCode);
      });

      if (structuredData.length > 0) {
        setAllTransferPartner(structuredData);
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    getProgramsAssignedToTransferPartner();
  }, []);

  const getProgramsAssignedToTransferPartner = async () => {
    try {
      let config = {
        method: "get",
        url: `${baseUrl}/transferPartner/getProgramsAssignedToTransferPartner`,
        // headers: {
        //   Authorisation: `Bearer ${localStorage.getItem('accessToken')}`,
        // },
        params: {
          transferPartnerId: JSON.parse(localStorage.getItem("transferPartner"))
            .id,
        },
      };

      const storedData = JSON.parse(localStorage.getItem("userInfo"));

      const response = await axios(config);

      if (response.data && response.data.length > 0) {
        let structuredData = [];
        let temp = response.data.map((item) => {
          addDataToProgramDataStore(item?.programCode, item);
          return structuredData.push(item?.programCode);
        });

        if (structuredData.length > 0) {
          setAllPrograms(structuredData);
        }
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (isSubmittedSuccessfull) {
      const timer = setTimeout(() => {
        setIsSubmittedSuccessfull(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isSubmittedSuccessfull]);

  const handlePointInputChange = (e) => {
    let value = e.target.value;
    let maxPoints = JSON.parse(localStorage.getItem("userPoints"));
    let minPoints = 0;

    if (value === "") {
      setPoints("");
      setDebouncedValue("");
    } else if (!isNaN(value)) {
      value = Number(value);
      if (value >= minPoints && value <= maxPoints) {
        setPoints(value);
        setDebouncedValue(value);
      } else if (value < minPoints) {
        setPoints(minPoints);
        setDebouncedValue(minPoints);
      } else if (value > maxPoints) {
        setPoints(maxPoints);
        setDebouncedValue(maxPoints);
      }
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      handleCalculate();
    }, debounceDelay);

    return () => {
      clearTimeout(handler);
    };
  }, [points]);

  return (
    <Box className="mainBox">
      <Box
        style={{
          // border: "1px solid black",
          width: "100%",
          margin: "0px 2px",
          padding: "20px 0px",
          // padding: "20px 70px",
        }}
      >
        <Box style={{ height: "100vh", position: "relative" }}>
          <Box
            sx={{
              //   position: 'absolute',
              width: "100%",
              //   height: '70%',
              backgroundColor: "white",
              borderRadius: "30px 30px 0px 0px",
            }}
          >
            <Box
              className="marginBox"
              // style={{ margin: "0px 32px" }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Box
                  className="pointExchangeBox"
                  // style={{
                  //   width: "100%",
                  //   display: "flex",
                  //   flexDirection: "column",
                  //   alignItems: "center",
                  //   margin: "25px 25px 25px 25px",
                  //   borderRadius: "30px",
                  //   // border: "1px solid",
                  // }}
                  style={{}}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      // marginBottom: "30px",
                      alignItems: "center",
                      // border: "1px solid",
                    }}
                  >
                    <Box style={{}}>
                      <Typography
                        style={{ fontSize: "20px", fontWeight: 600 }}
                        variant="h6"
                      >
                        Point Exchange
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        width: "80px",
                        height: "80px",
                      }}
                    >
                      <img
                        src={
                          `${baseUrl}` +
                          `${
                            JSON.parse(localStorage.getItem("transferPartner"))
                              ?.partnerLogoUri
                          }`
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Box>

                    <Box
                      onClick={() => {
                        navigate("/");
                        logout();
                      }}
                      style={{
                        display: "flex",
                        cursor: "pointer",
                        width: "128px",
                        height: "50px",
                        backgroundColor: "#F2F5FA",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "10px",
                      }}
                    >
                      <Typography
                        style={{ fontWeight: "bold", marginRight: "15px" }}
                      >
                        Logout
                      </Typography>
                      <ExitToAppIcon />
                    </Box>
                  </Box>

                  {/* --------------- */}
                  <Box
                    style={{
                      width: "95.5%",
                      backgroundColor: "#F1F4FA",
                      //   height: '500px',
                      marginTop: "20px",
                      borderRadius: "10px",
                      padding: "30px",
                      // border: "1px solid",
                    }}
                  >
                    {/* member id name  */}
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // border: "1px solid",
                      }}
                    >
                      <Box>
                        <Typography
                          id="memberIdTypo"
                          // style={{ fontWeight: "bold" }}
                        >
                          Member Id
                        </Typography>
                        <Typography
                          style={{ fontSize: "14px", marginTop: "7px" }}
                        >
                          {/* {parsedData
                            ? parsedData?.memberId
                            : decoded?.memberId} */}

                          {
                            JSON.parse(localStorage.getItem("userInfo"))
                              ?.memberId
                          }
                          {/* {partnerMemberId} */}
                        </Typography>
                        <Box>
                          {isSubmittedSuccessfull && (
                            <ConfettiExplosion
                              floorHeight={800}
                              floorWidth={1600}
                              duration={2000}
                            />
                          )}
                        </Box>
                      </Box>
                      <Box style={{ textAlign: "center" }}>
                        <Typography
                          id="memberIdTypo"
                          // style={{ fontWeight: "bold" }}
                        >
                          Member Name
                        </Typography>
                        <Typography
                          style={{ fontSize: "14px", marginTop: "7px" }}
                        >
                          {/* {partner.label} */}
                          {/* {decoded?.firstName} */}
                          {/* {parsedData
                            ? parsedData?.memberFirstName
                            : decoded?.firstName} */}

                          {`${
                            JSON.parse(localStorage.getItem("userInfo"))
                              ?.firstName
                          }` +
                            " " +
                            `${
                              JSON.parse(localStorage.getItem("userInfo"))
                                ?.lastName
                            }`}
                        </Typography>
                        <Box>
                          {isSubmittedSuccessfull && (
                            <ConfettiExplosion
                              floorHeight={800}
                              floorWidth={1600}
                              duration={2000}
                            />
                          )}
                        </Box>
                      </Box>
                      <Box style={{ textAlign: "right" }}>
                        <Typography
                          id="memberIdTypo"
                          // style={{ fontWeight: "bold" }}
                        >
                          Total Points
                        </Typography>
                        <Typography
                          style={{ fontSize: "14px", marginTop: "7px" }}
                        >
                          {JSON.parse(localStorage.getItem("userPoints"))}
                        </Typography>
                        <Box>
                          {isSubmittedSuccessfull && (
                            <ConfettiExplosion
                              floorHeight={800}
                              floorWidth={1600}
                              duration={2000}
                            />
                          )}
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      style={{ borderTop: "1px solid grey", marginTop: "6px" }}
                    ></Box>

                    {/* amount of point exchange */}

                    <Box>
                      <Box
                        style={{
                          marginTop: "30px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            marginBottom: "10px",
                          }}
                        >
                          Amount of points to Exchange
                        </Typography>

                        <Typography
                          style={{
                            fontSize: "12px",
                            marginBottom: "7px",
                            marginLeft: "6px",
                            color: "#3464B3",
                            fontWeight: "bold",
                          }}
                        >
                          {`(Slider with ${
                            JSON.parse(localStorage.getItem("transferPartner"))
                              ?.pointsIncrement
                              ? JSON.parse(
                                  localStorage.getItem("transferPartner")
                                )?.pointsIncrement
                              : 2
                          }-Point Increment per Step)`}
                        </Typography>
                      </Box>

                      <Box
                        className="sliderMainBox"
                        // style={{
                        //   display: "flex",
                        //   alignItems: "center",
                        //   justifyContent: "space-between",
                        //   width: "100%",
                        // }}
                        // style={{ border: "1px solid" }}
                      >
                        <Box
                          className="sliderTextfieldBox"
                          // style={{
                          //   // border: "1px solid",
                          //   width: "80%",
                          //   display: "flex",
                          //   alignItems: "center",
                          //   justifyContent: "space-between",
                          // }}
                          style={{
                            border: "1px solid #00000080",
                            backgroundColor: "white",
                            borderRadius: "20px",
                          }}
                        >
                          <Box
                            style={{
                              width: "85%",
                              padding: "10px",
                              backgroundColor: "white",
                              height: "60px",
                              display: "flex",
                              // alignItems: 'center',
                              borderRadius: "20px",
                              marginTop: "10px",
                              position: "relative",
                              // border: "1px solid",
                            }}
                          >
                            {/* JSON.parse(localStorage.getItem("transferPartner"))
?.partnerLogoUri */}

                            <Slider
                              value={points}
                              defaultValue={points}
                              min={0}
                              step={
                                JSON.parse(
                                  localStorage.getItem("transferPartner")
                                )?.pointsIncrement
                                  ? JSON.parse(
                                      localStorage.getItem("transferPartner")
                                    )?.pointsIncrement
                                  : 2
                              }
                              max={JSON.parse(
                                localStorage.getItem("userPoints")
                              )}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              onChange={(e) => {
                                setPoints(e.target.value);
                                setTransferedPoints(e.target.value);
                              }}
                              style={{ color: "black" }}
                              onChangeCommitted={handleCalculate}
                            />

                            <Box
                              style={{
                                position: "absolute",
                                bottom: "4px",
                                left: "7px",
                                backgroundColor: "black",
                                borderRadius: "12px",
                                // padding: "2px 18px",
                                border: "1px solid",
                                width: "70px",
                                height: "35px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                style={{
                                  // padding: "7px",
                                  backgroundColor: "#F6E5E5",
                                  height: "20px",
                                  borderRadius: "12px",
                                  width: "40px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: "11px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  0
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              style={{
                                position: "absolute",
                                bottom: "3px",
                                right: "5px",
                                backgroundColor: "#F6E5E5",
                                borderRadius: "12px",
                                padding: "2px 10px",
                                textAlign: "center",
                              }}
                            >
                              <Typography style={{ fontSize: "10px" }}>
                                Total Points
                              </Typography>
                              <Typography
                                style={{ fontSize: "11px", fontWeight: "bold" }}
                              >
                                {JSON.parse(localStorage.getItem("userPoints"))}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            style={{
                              width: "13%",
                              marginLeft: "10px",
                              marginTop: "6px",
                              paddingRight: "7px",
                              textAlign: "center",
                            }}
                          >
                            <Typography
                              style={{ marginBottom: "5px", fontSize: "14px" }}
                            >
                              Choosen Points
                            </Typography>
                            <TextField
                              value={points}
                              type="number"
                              disabled={
                                JSON.parse(
                                  localStorage.getItem("transferPartner")
                                )?.pointsIncrement
                                  ? true
                                  : false
                              }
                              sx={{
                                "& .MuiInputBase-input": {
                                  padding: "8px 8px",
                                },
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "black",
                                  color: "black",
                                },
                              }}
                              style={{
                                backgroundColor: "white",
                                borderRadius: "15px",

                                padding: "0px",
                              }}
                              onChange={(e) => {
                                handlePointInputChange(e);
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    {/* Into which Loyalty Currency do you want to exchange? */}

                    <Box>
                      <Box style={{ marginTop: "30px" }}>
                        <Typography
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          Into which Loyalty Currency do you want to exchange?
                        </Typography>
                      </Box>

                      <Box className="transferMainBox">
                        {programPoints.length === 0 && (
                          <Box
                            style={{
                              width: "100%",
                              height: "50vh",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {/* <CircularProgress style={{ color: "black" }} /> */}
                            <Typography
                              style={{ fontSize: "18px", fontWeight: "bold" }}
                            >
                              No Programs found !
                            </Typography>
                          </Box>
                        )}
                        <Box
                          // sx={{
                          //   display: "grid",
                          //   gridTemplateColumns: "repeat(3, 1fr)",
                          // }}
                          // style={{
                          //   display: "flex",
                          //   justifyContent: "space-evenly",

                          //   flexWrap: "wrap",

                          //   gap: "15px",
                          // }}
                          // sx={{
                          //   display: "grid",
                          //   gridTemplateColumns:
                          //     "repeat(auto-fit, minmax(200px, 1fr))",
                          //   gap: "15px",
                          // }}
                          sx={{
                            display:
                              programPoints?.length > 1 ? "grid" : "flex",
                            gridTemplateColumns:
                              "repeat(auto-fit, minmax(200px, 2fr))",
                            gap: "20px",
                            justifyContent: "center",
                          }}
                        >
                          {programPoints.map((item, index) => {
                            let programCode = item["programCode"];
                            let programLogoUrl =
                              baseUrl +
                              getDataFromProgramDataStore(programCode)[
                                "programLogoUri"
                              ];

                            return (
                              <Box
                                className="transferSelectBox"
                                key={item?.programId}
                                sx={{
                                  width:
                                    programPoints?.length > 1 ? "95%" : "300px",
                                  height: "250px",
                                  // height: "250px",
                                  padding: "8px",
                                  border: "1px solid lightgray",
                                  borderRadius: "8px",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    width: "90px",
                                    height: "60px",
                                    margin: "5px 0px",
                                  }}
                                >
                                  <img
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "contain",
                                    }}
                                    src={programLogoUrl}
                                    alt={item?.programName}
                                  />
                                </Box>

                                <Box
                                  sx={{
                                    textAlign: "center",
                                    margin: "5px 0px",
                                  }}
                                >
                                  <Typography sx={{ fontSize: "12px" }}>
                                    {item?.programName}
                                  </Typography>
                                </Box>

                                <Box sx={{ margin: "5px 0px" }}>
                                  <Typography id="sidePointsTypo">
                                    {item?.toSidePoints}
                                  </Typography>
                                </Box>

                                <Box>
                                  <Button
                                    disabled={calculateLoading || points === 0}
                                    variant="contained"
                                    onClick={() => {
                                      setIsSubmittedSuccessfull(false);
                                      handleOpen();
                                      setSelectedToSideProgram(item);
                                    }}
                                    sx={{
                                      fontSize: "12px",
                                      textTransform: "none",
                                      backgroundColor: "black",
                                      color: "white",
                                    }}
                                  >
                                    Choose
                                  </Button>
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>

                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box className="modalMainBox">
                            <Box
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box>
                                <Typography style={{ fontWeight: "bold" }}>
                                  From
                                </Typography>
                              </Box>
                              <Box>
                                <Typography style={{ fontWeight: "bold" }}>
                                  Into
                                </Typography>
                              </Box>
                            </Box>
                            {/* border */}
                            <Box
                              style={{
                                borderTop: "1px solid gray",
                                marginTop: "10px",
                              }}
                            ></Box>

                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "30px",
                              }}
                            >
                              <Box style={{ width: "60px", height: "60px" }}>
                                <img
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                  src={
                                    `${baseUrl}` +
                                    `${
                                      JSON.parse(
                                        localStorage.getItem("transferPartner")
                                      )?.partnerLogoUri
                                    }`
                                  }
                                />
                                {/* <img
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                  src={
                                    `${baseUrl}` +
                                    `${
                                      getDataFromTransferPartnerDataStore(
                                        getDataFromTransferPartnerDataStore(
                                          "SELECTED_TRANSFER_PARTNER_CODE"
                                        )
                                      )?.partnerLogoUri
                                    }`
                                  }
                                /> */}
                              </Box>
                              <Box style={{ width: "40%" }}>
                                <Box style={{ textAlign: "center" }}>
                                  <Typography
                                    style={{ color: "gray", fontSize: "14px" }}
                                  >
                                    Exchange Into
                                  </Typography>
                                  <div className="arrow-container">
                                    <div className="arrow">
                                      <div className="arrow-head"></div>
                                    </div>
                                  </div>
                                </Box>
                              </Box>
                              <Box style={{ width: "60px", height: "60px" }}>
                                {selectedToSideProgram && (
                                  <img
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "contain",
                                    }}
                                    src={
                                      `${baseUrl}` +
                                      `${
                                        getDataFromProgramDataStore(
                                          selectedToSideProgram.programCode
                                        )["programLogoUri"]
                                      }`
                                    }
                                  />
                                )}
                              </Box>
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "10px",
                              }}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "flex-end",
                                }}
                              >
                                <Typography style={{ fontWeight: "bold" }}>
                                  {points}
                                </Typography>
                                {/* <Typography style={{ fontWeight: 'bold' }}>{points}</Typography> */}
                                <Typography
                                  style={{
                                    fontSize: "11px",
                                    marginLeft: "4px",
                                    marginBottom: "3px",
                                  }}
                                >
                                  Points
                                </Typography>
                              </Box>

                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "flex-end",
                                }}
                              >
                                <Typography style={{ fontWeight: "bold" }}>
                                  {selectedToSideProgram?.toSidePoints}
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: "11px",
                                    marginLeft: "4px",
                                    marginBottom: "3px",
                                  }}
                                >
                                  Points
                                </Typography>
                              </Box>
                            </Box>

                            {/* enter memebership id */}
                            <Box>
                              <Box style={{ marginTop: "15px" }}>
                                <Typography
                                  id="modalMembershipIdTypo"
                                  // style={{ fontWeight: "bold" }}
                                >
                                  {`Enter your ${selectedToSideProgram?.programName} membership Id*`}
                                </Typography>
                              </Box>
                              <Box style={{ width: "100%", marginTop: "7px" }}>
                                <TextField
                                  value={memberId}
                                  onChange={(e) => setmemberId(e.target.value)}
                                  style={{ padding: "0px" }}
                                  fullWidth
                                />
                              </Box>
                            </Box>

                            {/* buttons */}
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Box style={{ width: "28%" }}>
                                <Button
                                  fullWidth
                                  onClick={handleClose}
                                  style={{
                                    padding: "10px 50px",
                                    border: "1px solid #000000",
                                    textTransform: "none",
                                    backgroundColor: "white",
                                    color: "black",
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Box>
                              <Box style={{ width: "28%" }}>
                                <Button
                                  disabled={memberId === ""}
                                  fullWidth
                                  onClick={handleSubmit}
                                  variant="contained"
                                  style={{
                                    padding: "10px 50px",
                                    textTransform: "none",
                                    // backgroundColor: "black",
                                    backgroundColor:
                                      memberId === ""
                                        ? "rgb(229, 229, 229)"
                                        : "black",
                                    opacity: memberId === "" ? 0.5 : 1,
                                  }}
                                >
                                  {onSubmitLoading ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    "Submit"
                                  )}
                                  {/* Submit */}
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        </Modal>
                        {/* ------success modal---------- */}
                        <SuccessModal
                          succcessModal={succcessModal}
                          handleCloseSuccessModal={handleCloseSuccessModal}
                          transferedPoints={transferedPoints}
                          selectedToSideProgram={selectedToSideProgram}
                          setSucccessModal={setSucccessModal}
                          setTransferedPoints={setTransferedPoints}
                          setSelectedToSideProgram={setSelectedToSideProgram}
                          doTransferPoints={doTransferPoints}
                          setDoTransferPoints={setDoTransferPoints}
                        />
                        {/* ---------------- */}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
