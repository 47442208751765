import { Box, Button, Modal, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const successModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 5,
  borderRadius: "10px",
};

const SuccessModal = ({
  succcessModal,
  handleCloseSuccessModal,
  transferedPoints,
  selectedToSideProgram,
  setSucccessModal,
  setTransferedPoints,
  setSelectedToSideProgram,
  doTransferPoints,
  setDoTransferPoints,
}) => {
  const handleCloseClick = () => {
    setSucccessModal(false);
    setTransferedPoints(0);
    setSelectedToSideProgram(null);
    setDoTransferPoints(null);
  };

  function getFormattedDate() {
    try {
      const date = new Date();

      if (isNaN(date.getTime())) {
        throw new Error("Invalid Date");
      }

      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const day = date.getDate();
      const month = monthNames[date.getMonth()];
      const year = date.getFullYear();

      const daySuffix = (day) => {
        if (typeof day !== "number" || day <= 0 || day > 31) return "";
        if (day > 3 && day < 21) return "th";
        switch (day % 10) {
          case 1:
            return "st";
          case 2:
            return "nd";
          case 3:
            return "rd";
          default:
            return "th";
        }
      };

      return `${day}${daySuffix(day)} ${month}, ${year}`;
    } catch (error) {
      console.error("Error formatting the date:", error);
      return "Invalid date format";
    }
  }

  return (
    <Modal
      //   open={true}
      open={succcessModal}
      onClose={handleCloseSuccessModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={successModalStyle}>
        <Box
          style={{
            width: "100%",

            backgroundColor: "#F5F5F5",
            height: "158px",
            borderRadius: "10px",
            // padding: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              width: "96.5%",
              borderBottom: "1px solid #D3D3D3",
              //   paddingBottom: "5px",
              padding: "10px",
            }}
          >
            <Box>
              <CheckCircleIcon
                style={{
                  color: "#16B464",
                  fontSize: "50px",
                }}
              />
            </Box>

            <Typography
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                marginLeft: "10px",
              }}
            >
              {`Your ${doTransferPoints?.transferPartnerPoints} points exchanged successfully!`}
            </Typography>
          </Box>

          {/* ------------------ */}
          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
              padding: "10px",
            }}
          >
            <Box style={{ width: "35%" }}>
              <Typography style={{ color: "#656464", fontSize: "14px" }}>
                Date
              </Typography>
              <Typography
                style={{
                  color: "#000000",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {getFormattedDate()}
              </Typography>
            </Box>
            <Box style={{ width: "35%" }}>
              <Typography style={{ color: "#656464", fontSize: "14px" }}>
                Exchanged Points
              </Typography>
              <Typography
                style={{
                  color: "#000000",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {`${selectedToSideProgram?.toSidePoints}`}
              </Typography>
            </Box>
            <Box style={{ width: "35%" }}>
              <Typography style={{ color: "#656464", fontSize: "14px" }}>
                Program Name
              </Typography>
              <Typography
                style={{
                  color: "#000000",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {`${selectedToSideProgram?.programName}`}
              </Typography>
            </Box>
          </Box>
          {/* ------------------ */}

          {/* <Box
            style={{
              backgroundColor: "#FFF6D7",
              width: "100%",
              textAlign: "center",
              height: "80px",
              //   padding: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography style={{ color: "#B99619", fontSize: "17px" }}>
              {`  Check your mail and use this points and get amazing deals on
              ${selectedToSideProgram?.programName} with unlimited rewards.`}
            </Typography>
          </Box> */}
        </Box>

        <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            onClick={handleCloseClick}
            variant="contained"
            style={{
              padding: "10px 50px",
              textTransform: "none",
              // backgroundColor: "black",
              backgroundColor: "black",
              opacity: 1,
            }}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SuccessModal;
